import React from 'react'
import SecondaryTestimonial from '../secondaryTestimonial'
import ConfirmationIcon from './confirmationIcon'

const Final = ({shop}) => {
  const renderHeader = () => {
    return (
      <>
        <div className="flex justify-center w-full py-10">
          <ConfirmationIcon height="24" width="24" className="mx-auto"/>
        </div>
        
        <h1 className="mb-4 text-3xl font-extrabold leading-10 tracking-tight text-center text-gray-900 sm:leading-none sm:text-3xl lg:text-5xl xl:text-5xl">
            <span className="text-peach-500 md:bg-clip-text md:text-transparent md:bg-gradient-to-r md:to-peach-500 md:from-indigo-700">
                Welcome to Batch
            </span>
        </h1>

        <p className="mt-2 text-lg font-light text-center text-gray-600">
          We've prepared some resources to help you get started.
        </p>
      </>
    )
  }

  const renderJoinFacebook = () => {
    return(
      <div class="rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
          <div>
            <span class="rounded-lg inline-flex p-3 bg-blue-50 text-blue-700 ring-4 ring-white">
              {/* <!-- Heroicon name: clock --> */}
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
              </svg>
            </span>
          </div>
          <div class="mt-8">
            <h3 class="text-lg font-medium">
              <a href="https://www.facebook.com/groups/sellwithbatch" class="focus:outline-none" target="_blank">
                <span class="absolute inset-0" aria-hidden="true"></span>
                Join the Facebook Community
              </a>
            </h3>
            <p class="mt-2 text-sm text-gray-500">
              Shopify retailers from around the world are taking advantage of Auction Marketing to drive results. Join the conversation on Facebook today!
            </p>
          </div>
          <span class="absolute top-6 right-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
          </span>
        </div>
    )
  }

  const renderReadTheBlog = () => {
    return(
      <div class="sm:rounded-tr-lg relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
        <div>
          <span class="rounded-lg inline-flex p-3 bg-purple-50 text-purple-700 ring-4 ring-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
            </svg>
          </span>
        </div>
        <div class="mt-8">
          <h3 class="text-lg font-medium">
            <a href="/blog" class="focus:outline-none" target="_blank">
              <span class="absolute inset-0" aria-hidden="true"></span>
               Auction Marketing Tips for Shopify
            </a>
          </h3>
          <p class="mt-2 text-sm text-gray-500">
            We've distilled some pro tips for you to take advantage of Batch creative and innovative ways sure to <em>wow</em> your customers.
          </p>
        </div>
        <span class="absolute top-6 right-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
            <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
          </svg>
        </span>
      </div>
    )
  }

  const renderBookDemo = () => {
    return(
        <div class="sm:rounded-bl-lg relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
          <div>
            <span class="rounded-lg inline-flex p-3 bg-yellow-50 text-yellow-700 ring-4 ring-white">
              {/* <!-- Heroicon name: receipt-refund --> */}
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </span>
          </div>
          <div class="mt-8">
            <h3 class="text-lg font-medium">
              <a href="/request-demo" class="focus:outline-none" target="_blank">
                <span class="absolute inset-0" aria-hidden="true"></span>
                One-on-One Onboarding
              </a>
            </h3>
            <p class="mt-2 text-sm text-gray-500">
            Want to implement best practices from the start? Book us for a personalized assisted setup and we'll configure Batch with you on screenshare. 
            </p>
          </div>
          <span class="absolute top-6 right-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
          </span>
        </div>
    )
  }

  const renderWatchVideo = () => {
    return(
        <div class="rounded-bl-lg rounded-br-lg sm:rounded-bl-none relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
          <div>
            <span class="rounded-lg inline-flex p-3 bg-peach-50 text-peach-700 ring-4 ring-white">
              {/* <!-- Heroicon name: badge-check --> */}
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </span>
          </div>
          <div class="mt-8">
            <h3 class="text-lg font-medium">
              <a href="https://www.youtube.com/watch?v=oOmWNvhpiP4&ab_channel=BatchCommerce" class="focus:outline-none" target="_blank">
                <span class="absolute inset-0" aria-hidden="true"></span>
                Watch the Introduction Video
              </a>
            </h3>
            <p class="mt-2 text-sm text-gray-500">
              Watch this 12-minute introductory video to get familiar with the features you'll use to collect and convert offers into revenue using Batch. 
            </p>
          </div>
          <span class="absolute top-6 right-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
          </span>
        </div>
    )
  }

  const renderResourceGrid = () => {
    return(
      <div class="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
        {/* Join the Facebook Community */}
        {renderJoinFacebook()}

        {/* Auction Marketing Handbook for Shopify */}
        {renderReadTheBlog()}

        {/* Book a demo */}
        {renderBookDemo()}

        {/* See how Batch works */}
        {renderWatchVideo()}
      </div>
    )
  }

  const testimonialPeople = ["alexis", "brie", "jayna", "maegan"]
  const testimonialPerson = testimonialPeople[Math.floor(Math.random()*testimonialPeople.length)]

  return(
    <div className="tracking-wide ">
      <div className="px-4 sm:mx-auto sm:w-full sm:max-w-7xl">
          <div className="">
            <div>
              {renderHeader()}
            </div>

            <div className="z-10 w-9/12 mx-auto mt-6 -mb-6">
              {renderResourceGrid()}
            </div>

            <div class="z-0">
              <SecondaryTestimonial person={testimonialPerson} fontSize="md" decoration="off"/>
            </div>
          </div>
      </div>
    </div>
  )
}

export default Final