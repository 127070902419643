import React from 'react'
import BenefitsTextCarousel from '../benefitsTextCarousel'

const Lead = ({submit, emailValidationErrorMessage}) => {
  return(
    <div className="tracking-wide divide-y divide-gray-200">
      <div className="px-4 sm:mx-auto sm:w-full sm:max-w-full">

        <h1 className="mt-6 mb-4 text-3xl font-extrabold leading-10 tracking-tight text-center text-gray-900 sm:leading-none sm:text-3xl lg:text-5xl xl:text-5xl">
            <span className="text-peach-500 md:bg-clip-text md:text-transparent md:bg-gradient-to-r md:to-peach-500 md:from-indigo-700">
                Signup 
            </span>
        </h1>

        <h2 className="mt-4 text-4xl font-bold leading-tight text-center text-gray-900">
          Get unlimited offers for free
        </h2>
        
        <p className="mt-2 text-lg font-light text-center text-gray-600">
          Join hundreds of Shopify retailers using Batch to <BenefitsTextCarousel punctuation={false} inline={false}/>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-2xl">
        <div className="px-4 py-8 sm:px-10">
          <div className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-lg text-gray-700">
                Enter your email address
              </label>
              <div className="mt-1 text-lg">
                <input id="email" name="email" type="email" autoComplete="email" required placeholder={"luke@rebelalliance.org"}
                       className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
                <div>{emailValidationErrorMessage}</div>
              </div>
            </div>
            <div>
              <label htmlFor="sales" className="block text-lg text-gray-700">Your store's average monthly revenue</label>
              <select id="sales" name="sales"
                      className="block w-full py-3 pl-3 pr-10 mt-1 text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option value={'tiny'} selected>&lt; $1000</option>
                <option value={'small'}>$1000 - $10000</option>
                <option value={'medium'}>$10000 - $50000</option>
                <option value={'large'}>$50000+</option>
              </select>
            </div>
            <div>
              <label htmlFor="batch-purpose" className="block text-lg text-gray-700">What is the #1 thing you want to accomplish with Batch?</label>
              <select id="batch-purpose" name="batch-purpose"
                      className="block w-full py-3 pl-3 pr-10 mt-1 text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option value={'slow-inventory'} selected>Sell slow moving inventory</option>
                <option value={'coupons'}>Stop having to use coupons</option>
                <option value={'cart-abandonment'}>Fight cart abandonment</option>
                <option value={'sales-increase'}>General sales increase</option>
                <option value={'other'}>Other</option>
              </select>
            </div>

            <p className="mt-2 text-sm font-light text-center text-gray-600">
              By signing up, you agree to the Batch <a href="/tos" className="text-peach-600" target="_blank">Terms of Service</a> and <a href="/privacy" className="text-peach-600" target="_blank">Privacy Policy</a>
            </p>

            <div>
              <button type="button"
                      className="flex justify-center w-full px-5 py-3 text-xl font-semibold text-white border border-l-0 border-r-0 border-transparent rounded-md shadow-sm bg-gradient-to-r from-indigo-700 to-peach-500 hover:bg-gradient-to-l hover:from-peach-500 hover:to-indigo-700" 
                      onClick={async () => { await submit();}}>
                SETUP MY FREE ACCOUNT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Lead