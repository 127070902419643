import React from 'react'
import Img from 'gatsby-image'
import { graphql, StaticQuery } from "gatsby"

// Call it like this and it will spit out the Gatsby Img tag and deal with the query:
// <Image fileName={"batch-icon.png"} alt={"Batch Icon"} className={"classes here"} />

const Image = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.fileName);
      });
      if (!image) { return null; }
      
      const imageSizes = image.node.childImageSharp.fluid;
      return (
        <Img
          alt={props.alt}
          fluid={imageSizes}
          className={props.className}
        />
      );
    }}
  />
)

export default Image