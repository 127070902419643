import React, { useState } from "react"
import Image from '../image'
import ConfirmationIcon from './confirmationIcon'
import QuestionIcon from './questionIcon'

const Customize = ({submit}) => {
  const [percentOff, changePercentOff] = useState(50);
  const [daysExpire, changeDayExpire] = useState(7);
  const [selectedPlan, setSelectedPlan] = useState(0);
  const renderHeader = () => {
    return (
      <div>
          <Image fileName="batch-icon.png" alt={'Batch Icon'} className={"w-12 h-12 mx-auto"} />

          <h1 className="mt-6 mb-4 text-3xl font-extrabold leading-10 tracking-tight text-center text-gray-900 sm:leading-none sm:text-3xl lg:text-5xl xl:text-5xl">
              <span className="text-peach-500 md:bg-clip-text md:text-transparent md:bg-gradient-to-r md:to-peach-500 md:from-indigo-700">
                  Your account is almost ready
              </span>
          </h1>

          <p className="mt-2 text-lg font-light text-center text-gray-600">
            Configure how Batch offers will work for your store
          </p>
      </div>
    )
  }
  
  const renderMaxDiscountField = () => {
    return (
      <div className="mx-auto ">
        <div className="mt-1 text-lg">
            <div className="flex flex-row items-center">
                <div className="flex-col min-w-1/2">
                  <label htmlFor={"maximumDiscountPercentage"} className="block text-lg text-gray-700">
                    What is the lowest offer you want to allow?
                  </label>
                </div>
                
                <div className="flex-col ml-auto min-w-1/4">
                  <div className="relative">
                      <input min={1} max={99} defaultValue={50} onChange={(e) => {changePercentOff(+e.currentTarget.value)}} name={'maximumDiscountPercentage'} id={'maximumDiscountPercentage'}
                            className="block w-full px-3 py-2 text-indigo-600 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
                      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span class="text-gray-500 sm:text-sm" id="price-currency">
                          percent off
                        </span>
                      </div>
                    </div>
                </div>
            </div>

            <div>
              <p className="mt-1 text-sm text-gray-500" id="discount-description">
                  <div className="flex flex-row items-center">
                    <div>
                        <QuestionIcon color="gray-400" height="5" width="5"/>
                    </div>
                    <div className="ml-1">
                        i.e. Customers will be able to submit offers to purchase products at up to {percentOff}% off.
                    </div>
                  </div>
                </p>  
            </div>    
        </div>
      </div>
    )
  }

  const renderOfferExpiryField = () => {
    return(
      <div className="mx-auto mt-6">
          <div className="mt-1 text-lg">
              <div className="flex flex-row items-center">
                  <div className="flex-col min-w-1/2">
                    <label htmlFor={"daysUntilOfferExpires"} className="block text-lg text-gray-700">
                        How soon should customer offers expire?
                    </label>
                  </div>
                  
                  <div className="flex-col ml-auto min-w-1/4">
                    <div className="relative">
                        <input min={1} max={14} defaultValue={7} onChange={(e) => {changeDayExpire(+e.currentTarget.value)}} name={'daysUntilOfferExpires'} id={'daysUntilOfferExpires'}
                              className="block w-full px-3 py-2 text-indigo-600 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <span class="text-gray-500 sm:text-sm" id="price-currency">
                            days
                          </span>
                        </div>
                      </div>
                  </div>
              </div>

              <div>
                <p className="mt-1 text-sm text-gray-500" id="discount-description">
                    <div className="flex flex-row items-center">
                      <div>
                          <QuestionIcon color="gray-400" height="5" width="5"/>
                      </div>
                      <div className="ml-1">
                      If you don't accept an offer within {daysExpire} days, it will automatically expire.
                      </div>
                    </div>
                  </p>  
              </div>    
          </div>
        </div>
    )
  }

  const renderPricingPlans = () => {
    return(
      <div className="mt-6">
          <label htmlFor="email" className="block text-lg text-gray-700">
            Select an account plan
          </label>

          <div className="my-2">
              <fieldset>
                  <legend id="radiogroup-label" class="sr-only">
                    Batch Pricing
                  </legend>
                  <ul class="space-y-4" role="radiogroup" aria-labelledby="radiogroup-label">
                    {/* Free Plan */}
                    <li onClick={() => setSelectedPlan(0)} id="radiogroup-option-0" tabindex="0" role="radio" aria-checked="false" class="group relative bg-white rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500">
                      <div class="rounded-lg border border-gray-300 bg-white px-6 py-4 hover:border-gray-400 sm:flex sm:justify-between">
                        <div class="flex items-center">
                          <div class="text-sm">
                            <p class="font-medium text-gray-900">
                              Startup
                            </p>
                            <div class="text-gray-500">
                              <p class="sm:inline">Collect and activate unlimited offers</p>
                              <span class="hidden sm:inline sm:mx-1" aria-hidden="true">&middot;</span>
                              <p class="sm:inline">Activate offers for only 10% of sales Batch creates</p>
                            </div>
                          </div>
                        </div>
                        <div class="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                          <div class="font-medium text-gray-900">$0</div>
                          <div class="ml-1 text-gray-500 sm:ml-0">/mo</div>
                        </div>
                      </div>
                      {/* <!-- On: "border-indigo-500", Off: "border-transparent" --> */}
                      <div className={`absolute inset-0 rounded-lg border-2 pointer-events-none ${selectedPlan === 0 ? 'border-indigo-500': 'border-transparent'}`} aria-hidden="true"/>
                    </li>

                    {/* Starter Plan */}
                    <li onClick={() => setSelectedPlan(1)} id="radiogroup-option-1" tabindex="-1" role="radio" aria-checked="true" class="group relative bg-white rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500">
                      <div class="rounded-lg border border-gray-300 bg-white px-6 py-4 hover:border-gray-400 sm:flex sm:justify-between">
                        <div class="flex items-center">
                          <div class="text-sm">
                            <p class="font-medium text-gray-900">
                              Growth
                            </p>
                            <div class="text-gray-500">
                              <p class="sm:inline">Convert unlimited offers into orders</p>
                              <span class="hidden sm:inline sm:mx-1" aria-hidden="true">&middot;</span>
                              <p class="sm:inline">Customize checkout</p>
                              <span class="hidden sm:inline sm:mx-1" aria-hidden="true">&middot;</span>
                              <p class="sm:inline">Hide branding</p>
                              <span className="hidden sm:inline sm:mx-1" aria-hidden="true">&middot;</span>
                              <p className="sm:inline">Pay only 8% of the sales Batch generates</p>
                            </div>
                          </div>
                        </div>
                        <div class="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                          <div class="font-medium text-gray-900">$49.99</div>
                          <div class="ml-1 text-gray-500 sm:ml-0">/mo</div>
                        </div>
                      </div>
                      {/* <!-- On: "border-indigo-500", Off: "border-transparent" --> */}
                      <div className={`border-transparent absolute inset-0 rounded-lg border-2 pointer-events-none ${selectedPlan === 1 ? 'border-indigo-500': 'border-transparent'}`} aria-hidden="true"/>
                    </li>

                    {/* Starter Yearly Plan */}
                    <li onClick={() => setSelectedPlan(2)} id="radiogroup-option-2" tabindex="-1" role="radio" aria-checked="false" class="group relative bg-white rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500">
                      <div class="rounded-lg border border-gray-300 bg-white px-6 py-4 hover:border-gray-400 sm:flex sm:justify-between">
                        <div class="flex items-center">
                          <div class="text-sm">
                            <p class="font-medium text-gray-900">
                                Pro
                            </p>
                            <div class="text-gray-500">
                              <p class="sm:inline">Convert unlimited offers into orders</p>
                              <span class="hidden sm:inline sm:mx-1" aria-hidden="true">&middot;</span>
                              <p class="sm:inline">Customize checkout</p>
                              <span class="hidden sm:inline sm:mx-1" aria-hidden="true">&middot;</span>
                              <p class="sm:inline">Hide branding</p>
                              <span className="hidden sm:inline sm:mx-1" aria-hidden="true">&middot;</span>
                              <p className="sm:inline">+4% of sales generated by Batch</p>
                            </div>
                          </div>
                        </div>
                        <div class="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                          <div class="font-medium text-gray-900">$99.99</div>
                          <div class="ml-1 text-gray-500 sm:ml-0">/mo</div>
                        </div>
                      </div>
                      {/* <!-- On: "border-indigo-500", Off: "border-transparent" --> */}
                      <div className={`absolute inset-0 rounded-lg border-2 pointer-events-none ${selectedPlan === 2 ? 'border-indigo-500': 'border-transparent'} `} aria-hidden="true"/>
                    </li>

                  </ul>
                </fieldset>
          </div>
        </div>
    )
  }

  const renderConfirmationSection = () => {
    return(
      <div className="my-6 text-center">
          <button type="button" onClick={() => submit(percentOff, daysExpire, selectedPlan) }
                  className={`min-w-full text-center text-xl justify-center px-6 py-4 font-semibold leading-6 text-white transition duration-150 ease-in-out border border-l-0 border-r-0 border-transparent rounded-md shadow-sm focus:outline-none sm:mt-0 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto bg-gradient-to-r to-peach-500 from-indigo-700 hover:from-peach-500 hover:to-indigo-700`}>
              Create account
          </button>

          <p className="mt-2 text-sm text-gray-500" id="discount-description">
            <div className="flex flex-row items-center justify-center">
              <div>
                  <ConfirmationIcon color="peach-500" height="5" width="5"/>
                  {/* If the input for discount has a value, switch to <ConfirmationIcon /> */}
              </div>
              <div className="ml-1">
                  No-code setup
              </div>
              <div className="ml-1">
                  <ConfirmationIcon color="peach-500" height="5" width="5"/>
                  {/* If the input for discount has a value, switch to <ConfirmationIcon /> */}
              </div>
              <div className="ml-1">
                  Average 15x ROI
              </div>
              <div className="ml-1">
                  <ConfirmationIcon color="peach-500" height="5" width="5"/>
                  {/* If the input for discount has a value, switch to <ConfirmationIcon /> */}
              </div>
              <div className="ml-1">
                  Never miss a sale again
              </div>
            </div>
          </p>  
      </div>
    )
  }

  return(
    <div className="tracking-wide">
      <div className="px-4 divide-y divide-gray-200 sm:mx-auto sm:w-full sm:max-w-full">
            {renderHeader()}

            <div className="pt-4 mx-auto mt-4 mb-4 md:mt-6">
                <div>
                  {/* Hidden fields */}
                  <input type="hidden" defaultChecked={true} name={'isIntroductionEnabled'} id={'isIntroductionEnabled'} />
                  <input type="hidden" defaultChecked={true} name={'isAddressAutocompleteEnabled'} id={'isAddressAutocompleteEnabled'} />

                  {/* Max Discount */}
                  {renderMaxDiscountField()}

                  {/* Offer Expiry */}
                  {renderOfferExpiryField()}
                </div>

                {/* Pricing Plans */}
                {renderPricingPlans()}
                
                {/* Confirmation Section */}
                {renderConfirmationSection()}
            </div>
       </div>
    </div>
  )
}

export default Customize