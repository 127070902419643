import React from 'react'
import Img from "gatsby-image"
import { content, name, company, position } from './testimonials'
import { graphql, useStaticQuery } from "gatsby"

// You must call this component with props.person as a "string"
// i.e. <SecondaryTestimonial person="jayna"
// Available persons:
// - alexis
// - brie
// - jayna
// - maegan

const SecondaryTestimonial = (props) => {
    const imageData = useStaticQuery(graphql`
      query {
          alexis: file(relativePath: { eq: "alexis.png" }) {
              childImageSharp {
                  fluid {
                  ...GatsbyImageSharpFluid
                  }
              }
          },
          brie: file(relativePath: { eq: "brie.jpg" }) {
              childImageSharp {
                  fluid {
                  ...GatsbyImageSharpFluid
                  }
              }
          },
          jayna: file(relativePath: { eq: "jayna.png" }) {
              childImageSharp {
                  fluid {
                  ...GatsbyImageSharpFluid
                  }
              }
          },
          maegan: file(relativePath: { eq: "maegan-s.jpg" }) {
              childImageSharp {
                  fluid {
                  ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)

    const getImage = (props) => {
        switch (props.person) {
            case ("alexis" || "Alexis"):
                return imageData.alexis.childImageSharp.fluid
            case ("maegan" || "Maegan"):
                return imageData.maegan.childImageSharp.fluid
            case ("brie" || "Brie"):
                return imageData.brie.childImageSharp.fluid
            case ("jayna" || "Jayna"):
                return imageData.jayna.childImageSharp.fluid
            default:
                return imageData.alexis.childImageSharp.fluid
        }
    }

    const fontSize = () => {
        switch (props.fontSize) {
            case "sm":
                return "text-sm leading-5"
            case "md":
                return "text-md leading-6"
            case "lg":
                return "text-lg leading-7"
            case "xl":
                return "text-xl leading-8"
            case "2xl":
                return "text-2xl leading-9"
            default:
                return "text-xl leading-8" 
        }
    }

    return(
        <section className="py-4 overflow-hidden md:py-6 lg:py-8">
        <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class={`${props.decoration === "off" ? 'invisible' : ''}`}>
                <svg className="absolute transform top-full right-full translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" role="img" aria-labelledby="svg-testimonial">
                <defs>
                    <pattern id="ad119f34-7694-4c31-947f-5c9d249b21f3" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                    </pattern>
                </defs>
                <rect width="404" height="404" fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)" />
                </svg> 
            </div>

            <div className="relative">

            <blockquote className="mt-10">
                <div className={`max-w-3xl mx-auto font-light text-center text-gray-500 ${fontSize()}`}>
                <p>
                    {content(props)}
                </p>
                </div>
                <footer className="mt-8">
                <div className="md:flex md:items-center md:justify-center">
                    <div className="md:flex-shrink-0">
                    <Img
                        fluid={getImage(props)}
                        alt={`Testimonial from ${name(props)}`}
                        className={'mx-auto h-10 w-10 rounded-full'}
                    />
                    </div>
                    <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div className="text-base font-medium text-gray-900">{name(props)}</div>

                    <svg className="hidden w-5 h-5 mx-1 md:block text-peach-500" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div className="text-base font-medium text-gray-500">{position(props)}, {company(props)}</div>
                    </div>
                </div>
                </footer>
            </blockquote>
            </div>
        </div>
        </section>
    )
}

export default SecondaryTestimonial