import React from 'react'
import InstallForm from "../installForm"
import SecondaryTestimonial from '../secondaryTestimonial'
import Image from '../image'

const Install = () => {
  const testimonialPeople = ["alexis", "brie", "jayna", "maegan"]
  const testimonialPerson = testimonialPeople[Math.floor(Math.random()*testimonialPeople.length)]

  return(
    <div className="tracking-wide divide-y divide-gray-200">
      <div className="px-4 sm:mx-auto sm:w-full sm:max-w-full">
            <Image fileName="shopify.png" alt={'Shopify logo'} className={"w-16 h-16 mx-auto"} />
            <h1 className="mt-6 mb-4 text-3xl font-extrabold leading-10 tracking-tight text-center text-gray-900 sm:leading-none sm:text-3xl lg:text-5xl xl:text-5xl">
                <span className="text-peach-500 md:bg-clip-text md:text-transparent md:bg-gradient-to-r md:to-peach-500 md:from-indigo-700">
                    Connect your store 
                </span>
            </h1>

            <div className="block mx-auto mt-4 md:mt-6 lg:mt-8">
              <InstallForm redirectUri={`${process.env.GATSBY_REDIRECT_URI}/start?step=2`} size="medium" align="center"/>
            </div>

            <p className="mt-4 text-center text-gray-500 text-md">
              You'll receive offers you can instantly convert into new orders
            </p> 

            <SecondaryTestimonial person={testimonialPerson} fontSize="lg" />
       </div>
    </div>
  )
}

export default Install