import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from 'gatsby';
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import Lead from "../components/start/lead"
import Install from "../components/start/install"
import Customize from "../components/start/customize"
import Final from "../components/start/final"
import Image from '../components/image'

const Start = () => {
  const [isLoading, changeIsLoading] = useState(false);
  const [onboardingStep, changeOnboardingStep] = useState(0);
  const [emailValidationErrorMessage, setEmailValidationErrorMessage] = useState('');
  const [step, clearStep] = useQueryParam('step', NumberParam);
  const [shop] = useQueryParam('shop', StringParam);
  const [code, clearCode] = useQueryParam('code', StringParam);

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          pages {
            start {
              title
              description
              url
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    async function completeAuth() {
      const token = await((await fetch(`${process.env.GATSBY_BACKEND_URI}/auth/shopify/authToken?shop=${shop}&code=${code}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })).json());
      await fetch(`${process.env.GATSBY_BACKEND_URI}/auth/shopify/install?shop=${shop}&code=${token}`);
    }
    if (step && code && shop) {
      changeIsLoading(true);
      completeAuth().then(() => {
        clearStep(null);
        clearCode(null);
        window.analytics.track('start-customize');
        changeOnboardingStep(step);
        changeIsLoading(false);
        window.history.replaceState({}, document.title, "/start");
      });
    }
  }, []);

  const saveLead = async () => {
    const email = document.getElementById('email').value;
    const revenue = document.getElementById('sales').value;
    const purpose = document.getElementById('batch-purpose').value;

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = re.test(String(email).toLowerCase());
    if (!isValidEmail) {
      setEmailValidationErrorMessage('Please enter a valid email');
      return;
    }

    await fetch(`${process.env.GATSBY_BACKEND_URI}/view/lead`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        revenue,
        purpose,
      })
    });
    window.analytics.track('start-install');
    changeOnboardingStep(onboardingStep+1);
  }

  const saveCustomizationAndPlan = async (maximumDiscountPercentage, daysUntilOfferExpires, plan) => {
    const settings = {
      daysUntilOfferExpires,
      isAddressAutocompleteEnabled: true,
      isIntroductionEnabled: true,
      widgetLoadDelay: 0,
      maximumDiscountPercentage
    }
    await fetch(`${process.env.GATSBY_BACKEND_URI}/settings?shop=${shop}`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(settings),
      });
    let planResult;
    if (plan >= 0) {
      let planName = 'Startup Plan';
      if (plan === 1) {
        planName = 'Growth Plan';
      }
      if (plan === 2) {
        planName = 'Pro Plan';
      }
      planResult = await(await fetch(`${process.env.GATSBY_BACKEND_URI}/applicationCharge?shop=${shop}`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({plan: planName}),
        })).json();
    }
    window.analytics.track('start-final');
    if (!planResult || !planResult.applicationCharge) {
      window.open(
        `https://${shop}/${process.env.GATSBY_APP_REDIRECT_PATH}`,
        '_blank'
      );
      changeOnboardingStep(onboardingStep+1);
      return;
    }
    if (planResult.applicationCharge.confirmationUrl) {
      window.open(
        planResult.applicationCharge.confirmationUrl,
        '_blank'
      );
      changeOnboardingStep(onboardingStep+1);
      return;
    }
    if (planResult.applicationCharge.returnUrl) {
      window.open(
        planResult.applicationCharge.returnUrl,
        '_blank'
      );
      changeOnboardingStep(onboardingStep+1);
      return;
    }
    window.open(
      `https://${shop}/${process.env.GATSBY_APP_REDIRECT_PATH}`,
      '_blank'
    );
    changeOnboardingStep(onboardingStep+1);
  }

  const renderStep = () => {
    if (isLoading) {
      return null;
    }

    if (onboardingStep === 0) {
      return <Lead submit={saveLead} emailValidationErrorMessage={emailValidationErrorMessage}/>;
    }
    if (onboardingStep === 1) {
      return <Install />
    }
    if (onboardingStep === 2) {
      return <Customize submit={saveCustomizationAndPlan} />
    }
    return <Final shop={shop}/>
  }

  return (
    <>
      <Helmet>
        {/* Meta */}
        <html lang="en" />
        <title>{data.site.siteMetadata.pages.start.title}</title>
        <meta charSet="utf-8" />
        <meta name="title" content={data.site.siteMetadata.pages.start.title}/>
        <meta name="description" content={data.site.siteMetadata.pages.start.description} />
        <link rel="canonical" href={data.site.siteMetadata.pages.start.url} />
      </Helmet>

      <div className="flex items-center justify-center h-screen max-w-full max-h-screen min-h-screen px-4 mx-auto sm:px-6 lg:px-8 bg-wiggle-peach">
        <div className="p-8 bg-white rounded shadow-sm min-w-1/2 pt-auto">
          {renderStep()}
          <Image alt={'Batch Icon'} className={"w-24 h-6 mx-auto mt-4 border-none"} fileName="batch-logo-grayscale.png"  />
        </div>
      </div>
    </>
  )
}

export default Start
